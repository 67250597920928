import React from 'react';
import {graphql} from 'gatsby';

import Home from '~containers/home';
import Layout from '~components/layout';
import Seo from '~components/seo';
import {firebaseAnalytics} from '~utils/firebase';

const HomePage = ({location, data}) => {
  React.useEffect(() => {
    firebaseAnalytics.logEvent('Homepage_visited');
  }, []);
  const banners = data.allBanner.nodes;

  return (
    <Layout>
      <Seo title='Home' />
      <Home location={location} banners={banners} />
    </Layout>
  );
};

export const query = graphql`
  {
    allBanner {
      nodes {
        id
        caption
        imageUrl
        name
        type
        url
      }
    }
  }
`;

export default HomePage;
